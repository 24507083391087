import React from 'react'
import SbEditable from 'storyblok-react'

import FocusPointImg from '../FocusPoint/focusPointImg'
import Link from '../Link/link'

const Image = props => {
  const imageMaxSize = {
    width: 1200,
    height: 1200,
  }
  const blok = props.blok
  const aspectRatio =
    blok.aspect_ratio === 'original'
      ? [blok.image.imageSize.width, blok.image.imageSize.height]
      : blok.aspect_ratio !== ''
      ? blok.aspect_ratio.split(':').map(Number)
      : [3, 2] //default aspect ratio

  const size = {
    width: Math.floor(imageMaxSize.width),
    height: Math.floor(imageMaxSize.height / (aspectRatio[0] / aspectRatio[1])),
  }

  return (
    <SbEditable content={blok}>
      <FocusPointImg focusPoint={blok.image} size={size} alt={blok.caption} />
      {blok.caption.trim().length > 0 &&
        (blok.caption_link && blok.caption_link.cached_url.length > 0 ? (
          <p className="image-advanced__caption">
            <Link
              storyblokLink={blok.caption_link}
              target={
                blok.caption_link.linktype === 'url' ? '_blank' : undefined
              }
            >
              {blok.caption}
            </Link>
          </p>
        ) : (
          <p className="image-advanced__caption">{blok.caption}</p>
        ))}
    </SbEditable>
  )
}

export default Image
