import React from 'react'
import SbEditable from 'storyblok-react'
import FocusPointImg from '../FocusPoint/focusPointImg'

const CarouselSlide = props => (
  <SbEditable content={props.blok}>
    <div
      className={`ef-card--teaser carousel__slide ${
        props.blok.title.length === 0 && props.blok.description.length === 0
          ? '-no-gradient'
          : ''
      }`}
    >
      <FocusPointImg
        focusPoint={props.blok.image}
        size={{ width: 1200, height: 675 }}
        alt={props.blok.title}
        className="ef-card--teaser__img"
      />
      <div className="ef-card--teaser__inner">
        <div className="ef-card--teaser__content">
          <h4 className="ef-card--teaser__title">{props.blok.title}</h4>
          <div className="ef-card--teaser__text">
            <p>{props.blok.description}</p>
          </div>
        </div>
      </div>
    </div>
  </SbEditable>
)

export default CarouselSlide
