import React from 'react'
import Hero from '../Hero/hero'
import Link from '../Link/link'
import SEO from '../Seo/seo'
import { resizeWithFocusPoint } from '../../helpers/imageService'

const ProjectIntro = props => {
  return (
    <>
      <SEO
        title={
          props.blok.product_name ? props.blok.product_name : props.story.name
        }
        description={props.blok.description}
        image={
          props.blok.hero_background &&
          resizeWithFocusPoint(
            props.blok.hero_background.image,
            props.blok.hero_background.imageSize,
            props.blok.hero_background.focusPoint,
            { width: 1024, height: 1024 }
          ).url
        }
        pathname={props.story.full_slug}
      />
      <Hero
        focusPoint={props.blok.hero_background}
        title={props.story.name}
        tagList={props.story.tag_list}
        modifiers={props.blok.hero_gradients ? '-gradient' : ''}
      />
      <div className="ef-section -s">
        <div className="ef-container">
          <div className="ef-row">
            <div className="ef-col -s-12 -m-7">
              <p className="u-mb-s">
                {props.blok.product_name
                  ? props.blok.product_name
                  : props.story.name}
                {props.blok.year &&
                  props.blok.year.length > 0 &&
                  `\u2000|\u2000${props.blok.year}`}
              </p>
              <h3 className="project-intro__desc ef-text-subtitle">
                {props.blok.description}
              </h3>
            </div>
            <div className="ef-col u-mb-m -m-4 -m-offset-1">
              {props.blok.team && props.blok.team.length > 0 && (
                <div className="project-intro__item">
                  <span className="project-intro__item-label">Team</span>
                  <p className="project-intro__item-text">{props.blok.team}</p>
                </div>
              )}

              {props.blok.link && props.blok.link.cached_url && (
                <div className="project-intro__item">
                  <span className="project-intro__item-label">Link</span>
                  <p className="project-intro__item-text">
                    <Link storyblokLink={props.blok.link} target="_blank">
                      {props.blok.link_description !== ''
                        ? props.blok.link_description
                        : props.blok.link.cached_url}
                    </Link>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectIntro
