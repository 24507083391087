import React from 'react'
import SbEditable from 'storyblok-react'
import Modal from 'react-modal'
import ReactPlayer from 'react-player'

import FocusPointBg from '../FocusPoint/focusPointBg'
import Link from '../ImageAdvanced/image-advanced'

Modal.setAppElement('#___gatsby')

class VideoCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
    }

    this._handleOpenModal = this._handleOpenModal.bind(this)
    this._handleCloseModal = this._handleCloseModal.bind(this)
  }

  render() {
    return (
      <SbEditable content={this.props.blok}>
        <div className="video-advanced">
          <div className="dip-setter">
            <button
              className="ef-card--video"
              onClick={this._handleOpenModal}
              aria-label="Play video"
            >
              <FocusPointBg
                focusPoint={this.props.blok.poster}
                size={{ width: 1000, height: 600 }}
                className="ef-card--video__img"
                tabIndex="-1"
              />

              <div className="ef-card--video__icon-w">
                <span className="ef-card--video__icon ef-icon -play" />
              </div>
            </button>
          </div>
          {this.props.blok.caption &&
            this.props.blok.caption.trim().length > 0 &&
            (this.props.blok.caption_link &&
            this.props.blok.caption_link.cached_url.length > 0 ? (
              <p className="video-advanced__caption">
                <Link
                  storyblokLink={this.props.blok.caption_link}
                  target={
                    this.props.blok.caption_link.linktype === 'url'
                      ? '_blank'
                      : undefined
                  }
                >
                  {this.props.blok.caption}
                </Link>
              </p>
            ) : (
              <p className="video-advanced__caption">
                {this.props.blok.caption}
              </p>
            ))}
        </div>
        <Modal
          ariaHideApp={false}
          className="modal"
          overlayClassName="modal__overlay"
          onRequestClose={this._handleCloseModal}
          isOpen={this.state.modalIsOpen}
          closeTimeoutMS={150}
        >
          <div className="modal__wrap">
            <div className="ef-container">
              <div className="modal__content">
                <div className="modal__player-wrap">
                  <ReactPlayer
                    url={this.props.blok.video_link.cached_url}
                    playing
                    className="modal__player"
                    width="100%"
                    height="100%"
                    controls
                  />
                  <button
                    className="modal-close"
                    onClick={this._handleCloseModal}
                  >
                    <span className="ef-icon -close -light" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </SbEditable>
    )
  }

  _handleOpenModal() {
    this.setState({ modalIsOpen: true })
  }

  _handleCloseModal() {
    this.setState({ modalIsOpen: false })
  }
}

export default VideoCard
