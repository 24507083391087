import React from 'react'
import SbEditable from 'storyblok-react'
import ReactMarkdown from 'react-markdown'

import Link from '../Link/link'
import FocusPointImg from '../FocusPoint/focusPointImg'

const CTASimple = props => {
  return (
    <SbEditable content={props.blok}>
      <div>
        <div className="ef-row">
          <div className="ef-col -m-4">
            <h2
              className={`ef-h3 cta-simple-title  ${
                props.blok.description.trim() !== ''
                  ? 'u-s-mb-m u-xl-mb-l'
                  : 'u-s-mb-l u-xl-mb-xl'
              }`}
            >
              {props.blok.title}
            </h2>
            {props.blok.image !== '' && (
              <div className="ef-col -m-12 u-show-s-only u-s-p-0 u-s-pb-m">
                <FocusPointImg
                  focusPoint={props.blok.image}
                  size={{ width: 550, height: 330 }}
                  className="ef-card--teaser__img"
                />
              </div>
            )}
            {props.blok.description.trim() !== '' && (
              <ReactMarkdown
                className="u-mb-xl u-f-light cta-simple-description"
                source={props.blok.description}
              />
            )}
            {props.blok.cta_link.cached_url !== '' && (
              <Link
                className="ef-link--arrow u-m-pt-s"
                storyblokLink={props.blok.cta_link}
                target={
                  props.blok.cta_link.linktype === 'url' ? '_blank' : undefined
                }
                arrow="true"
              >
                {props.blok.cta_description}
                {props.blok.cta_link.linktype === 'url' && (
                  <span className="ef-icon -new-window u-ml-xs" />
                )}
              </Link>
            )}
          </div>
          {props.blok.image !== '' && (
            <div className="ef-col -m-7 -m-offset-1 u-show-m-up">
              <FocusPointImg
                focusPoint={props.blok.image}
                size={{ width: 550, height: 330 }}
                className="ef-card--teaser__img"
              />
            </div>
          )}
        </div>
      </div>
    </SbEditable>
  )
}

export default CTASimple
