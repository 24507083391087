import React from 'react'
import SbEditable from 'storyblok-react'
import ReactMarkdown from 'react-markdown'
import Link from '../Link/link'
import FocusPointImg from '../FocusPoint/focusPointImg'

const Location = props => {
  const imageMaxSize = {
    width: 800,
    height: 800,
  }

  return (
    <SbEditable content={props.blok}>
      <div className="ef-section -s">
        <div className="ef-row">
          <div className="ef-col" id={props.blok.name}>
            <h4>{props.blok.name}</h4>
          </div>
        </div>
        <div className="ef-row">
          <div className="ef-col -s-12 -m-6 u-s-mb-0 u-m-mb-0">
            <p className="ef-text-para -book location__address">
              {props.blok.address_link &&
              props.blok.address_link.cached_url.length > 0 ? (
                <Link
                  storyblokLink={props.blok.address_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {props.blok.address}
                </Link>
              ) : (
                props.blok.address
              )}
            </p>
          </div>
        </div>
        <div className="ef-row">
          <div className="ef-col -s 12 -m-7 u-s-pt-s">
            <ReactMarkdown
              source={props.blok.description}
              className="location__description u-f-light"
            />
          </div>
          <div className="ef-col -s-12 -m-3 -m-offset-1 u-s-pt-s">
            <Link
              storyblokLink={props.blok.careers_link}
              target="_blank"
              className="location__link u-f-book"
            >
              <span className="ef-icon -new-window u-mr-xs" />
              Creative Careers
            </Link>
          </div>
        </div>
        <div className="u-mt-xl location__gallery">
          {props.blok.images.map((focusPointImg, index) => {
            const squareImg = index % 2 !== props.locationIndex % 2

            const size = {
              width: imageMaxSize.width,
              height: squareImg
                ? imageMaxSize.height
                : Math.floor(imageMaxSize.height / (570 / 396)),
            }

            return (
              <div
                className={`location__img-w
              ${index % 2 !== props.locationIndex % 2 ? '-square' : ''}`}
                key={focusPointImg._uid}
              >
                <FocusPointImg
                  focusPoint={focusPointImg.image}
                  size={size}
                  alt={props.blok.name}
                  className="location__img ef-surface -deep-shadow -rounded"
                />
              </div>
            )
          })}
        </div>
      </div>
    </SbEditable>
  )
}

export default Location
