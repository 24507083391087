import React from 'react'
import Layout from '../layout'
import SEO from '../Seo/seo'
import JournalCard from '../JournalCard/journal-card'

const JournalIndex = props => {
  return (
    <Layout settings={props.settings}>
      <SEO
        title={props.story.name}
        pathname={props.story.full_slug}
        description={props.blok.intro}
      />

      <section className="ef-container">
        <div className="ef-section">
          <div className="ef-row">
            <div className="ef-col -m-5">
              <h3 className="ef-h3">{props.blok.title}</h3>
              <p className="ef-text-body u-f-light">{props.blok.intro}</p>
            </div>
          </div>
        </div>
      </section>

      <div className="ef-section u-pt-0">
        <div className="ef-container">
          <div className="ef-row">
            {props.blok.cards &&
              props.blok.cards.map(blok => (
                <div
                  className="ef-col -s-12 -m-6 -l-4 u-s-mb-m u-m-mb-s u-l-mb-m"
                  key={blok._uid}
                >
                  <JournalCard blok={blok} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default JournalIndex
