import React from 'react'
import SbEditable from 'storyblok-react'
import { Link as GatsbyLink } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import Link from '../Link/link'
import FocusPointImg from '../FocusPoint/focusPointImg'
import JournalCard from '../JournalCard/journal-card'

const CTABlock = props => {
  return (
    <SbEditable content={props.blok}>
      <div>
        <div className="ef-row">
          <div className="ef-col -s-12 -m-6">
            <h2
              className={`ef-h3 ${
                props.blok.description.trim() !== ''
                  ? 'u-s-mb-l u-xl-mb-xl'
                  : 'u-s-mb-xl u-xl-mb-xxl'
              }`}
            >
              {props.blok.title}
            </h2>
          </div>
          <div className="ef-col -m-6">
            {props.blok.cta_link.cached_url !== '' && (
              <div className="u-text-right">
                <Link
                  className="ef-link--arrow u-show-m-up u-m-pt-s"
                  storyblokLink={props.blok.cta_link}
                  target={
                    props.blok.cta_link.linktype === 'url'
                      ? '_blank'
                      : undefined
                  }
                  arrow="true"
                >
                  {props.blok.cta_description}
                  {props.blok.cta_link.linktype === 'url' && (
                    <span className="ef-icon -new-window u-ml-xs" />
                  )}
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="ef-row">
          <div className="ef-col -m-10 -l-8">
            {props.blok.description.trim() !== '' && (
              <ReactMarkdown
                className="u-mb-xl"
                source={props.blok.description}
              />
            )}
          </div>
        </div>

        {props.blok.items.length > 0 && !props.blok.single_item && (
          <div className="ef-row u-mb-m">
            {props.blok.items.map(story => {
              return (
                <div
                  className="ef-col -s-12 -m-6 -l-4 u-mb-s u-l-mb-m"
                  key={story.uuid}
                >
                  {typeof story === 'string' ? (
                    <small>Save & Reload the page to see the changes</small>
                  ) : (
                    <GatsbyLink
                      to={`/${story.full_slug}`}
                      className={`ef-card--teaser 
                      ${
                        !story.content.hero_gradients ? '-no-shadow' : ''
                      }  block-list__item`}
                    >
                      {story.content.featured_image && (
                        <FocusPointImg
                          focusPoint={story.content.featured_image}
                          size={{ width: 384, height: 430 }}
                          alt={story.name}
                          className="ef-card--teaser__img"
                        />
                      )}
                      <div className="ef-card--teaser__inner">
                        <div className="ef-card--teaser__content">
                          <h4 className="ef-card--teaser__title">
                            {story.name}
                          </h4>
                          <div className="ef-card--teaser__text">
                            {story.tag_list.join(', ')}
                          </div>
                        </div>
                      </div>
                    </GatsbyLink>
                  )}
                </div>
              )
            })}
          </div>
        )}

        {props.blok.single_item && props.blok.items.length === 1 && (
          <div className="ef-row u-mb-m">
            {!props.blok.items[0].content ? (
              <small>Save & Reload the page to see the changes</small>
            ) : (
              props.blok.items[0].content.cards
                .filter(card => card.showOnHomepage)
                .map(filteredCard => (
                  <div
                    className="ef-col -s-12 -m-6 -l-4 u-mb-s u-l-mb-m"
                    key={filteredCard._uid}
                  >
                    <JournalCard blok={filteredCard} />
                  </div>
                ))
            )}
          </div>
        )}
      </div>
    </SbEditable>
  )
}

export default CTABlock
