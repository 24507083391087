import PropTypes from 'prop-types'
import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import parseStoryFromNode from '../../helpers/parseStory'
import FocusPointImg from '../FocusPoint/focusPointImg'

const storiesQuery = graphql`
  query {
    allStoryblokEntry(
      filter: { full_slug: { regex: "/work/.*/" }, is_startpage: { eq: false } }
    ) {
      edges {
        node {
          id
          name
          created_at
          published_at
          uuid
          slug
          full_slug
          content
          is_startpage
          parent_id
          group_id
          tag_list
        }
      }
    }
  }
`

const RelatedProjects = props => {
  return (
    <StaticQuery
      query={storiesQuery}
      render={data => {
        if (data.allStoryblokEntry.edges.length === 0) return null
        const stories = data.allStoryblokEntry.edges
          .filter(story => {
            return (
              story.node.id !== props.story.id &&
              story.node.tag_list.map(tag => props.story.tag_list.includes(tag))
            )
          })
          .slice(0, 3)
          .map(story => parseStoryFromNode(story))

        if (stories.length) {
          return (
            <>
              <div className="ef-container">
                <hr className="section__splitter" />
              </div>
              <section className="ef-section">
                <div className="ef-container">
                  <div className="ef-row">
                    <div className="ef-col">
                      <h3 className="ef-h4 u-s-mb-l u-mx-auto">
                        Related Projects
                      </h3>
                    </div>
                  </div>
                  <div className="ef-row">
                    {stories.map(story => {
                      const { hero_gradients } = story.content

                      return (
                        <div
                          className="ef-col -s-12 -m-6 -l-4 u-mb-s u-l-mb-0"
                          key={story.uuid}
                        >
                          <Link
                            to={`/${story.full_slug}`}
                            className={`ef-card--teaser ${!hero_gradients &&
                              '-no-shadow'} block-list__item`}
                          >
                            {story.content.featured_image && (
                              <FocusPointImg
                                focusPoint={story.content.featured_image}
                                size={{ width: 384, height: 430 }}
                                alt={story.name}
                                className="ef-card--teaser__img"
                              />
                            )}
                            <div className="ef-card--teaser__inner">
                              <div className="ef-card--teaser__content">
                                <h4 className="ef-card--teaser__title ef-h4">
                                  {story.name}
                                </h4>
                                <div className="ef-card--teaser__text">
                                  {story.tag_list.join(', ')}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </section>
            </>
          )
        }
        return null
      }}
    />
  )
}

RelatedProjects.propTypes = {
  story: PropTypes.object.isRequired,
}

export default RelatedProjects
