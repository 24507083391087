import React from 'react'
import PropTypes from 'prop-types'
import posed from 'react-pose'
import { Link } from 'gatsby'

import { resize } from '../../helpers/imageService'
import BgImgLazyLoad from '../bgImgLazyLoad'
import activeBreakpoint from '../../helpers/breakpoint'

const VideoBG = posed.video({
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 350 },
  },
})

class HeroHomepage extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)

    this.videoRef = React.createRef()
    this.videoOverRef = []

    this.state = {
      videoReady: false,
      showVideoOver: false,
      videoOverPlaying: false,
      activeBreakpoint: '',
      videos: [
        {
          url:
            'https://player.vimeo.com/external/317042866.sd.mp4?s=d55e4e06a7568bff7530c00d8d4d7d0fd7def4d3&profile_id=165',
          showing: false,
        },
        {
          url:
            'https://player.vimeo.com/external/317044708.sd.mp4?s=9b9904fac00e5e21ba896c7eac35d8f89a80d153&profile_id=165',
          showing: false,
        },
        {
          url:
            'https://player.vimeo.com/external/317040885.sd.mp4?s=a84f5f149070f72071a8d02c75138ffa00c499a1&profile_id=165',
          showing: false,
        },
      ],
    }

    this._handleResize = this._handleResize.bind(this)
    this._handleShowVideo = this._handleShowVideo.bind(this)
    this._handleHideVideo = this._handleHideVideo.bind(this)
    this._setVideoReady = this._setVideoReady.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    const videoElem = this.videoRef.current
    if (videoElem) {
      videoElem.muted = true
      videoElem.setAttribute('muted', 'true') //see more: https://github.com/facebook/react/issues/10389
      videoElem.setAttribute('autoplay', 'true')

      videoElem.oncanplay = () => {
        if (this._isMounted) {
          videoElem.addEventListener('playing', () => this._setVideoReady())
          this.playVideo()
        }
      }
    }

    this._handleResize()
    window.addEventListener('resize', this._handleResize)
  }

  componentWillUnmount() {
    this._isMounted = false
    this.videoRef.current.removeEventListener('playing', this._setVideoReady)
    window.removeEventListener('resize', this._handleResize)
  }

  _handleResize() {
    this.resizeTimer && clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => {
      this.resizeTimer = null
      this.setState({ activeBreakpoint: activeBreakpoint() })
      if (
        this.state.activeBreakpoint === 's' &&
        this.props.backgroundVideoUrlMobile.length > 0
      ) {
        // only update src if different from current to avoid reloading video
        if (this.videoRef.current.src !== this.props.backgroundVideoUrlMobile) {
          this.videoRef.current.src = this.props.backgroundVideoUrlMobile
        }
      } else {
        if (this.videoRef.current.src !== this.props.backgroundVideoUrl) {
          this.videoRef.current.src = this.props.backgroundVideoUrl
        }
      }
    })
  }

  _setVideoReady() {
    this.setState({ videoReady: true })
  }

  playVideo() {
    if (this.videoRef.current && this.state.videoReady) {
      this.videoRef.current.play()
    }
  }

  _handleShowVideo(index) {
    const videos = this.state.videos
    videos[index].showing = true
    this.videoOverRef[index].pause()
    this.videoOverRef[index].currentTime = 0
    this.videoOverRef[index].muted = true
    this.videoOverRef[index].play()

    this.setState({ videos, videoOverPlaying: true })
  }

  _handleHideVideo(index) {
    const videos = this.state.videos
    videos[index].showing = false

    this.setState({ videos, videoOverPlaying: false })
  }

  render() {
    return (
      <div
        className={`ef-fullbleed hero -video -homepage ${this.props.modifiers}`}
        id="hero"
      >
        <div className="hero__video-wrap">
          <div className="hero__video-bg-w">
            <BgImgLazyLoad
              imgSrc={resize(this.props.backgroundVideoPoster, '')}
              render={({ image }) =>
                image && (
                  <div
                    className="hero__video-poster focuspoint__load-animation"
                    style={{
                      backgroundImage: `url('${image}')`,
                    }}
                  />
                )
              }
            />
            <VideoBG
              ref={this.videoRef}
              pose={this.state.videoReady ? 'visible' : 'hidden'}
              className="hero__video-bg ef-fullbleed__bg"
              muted
              playsInline
              loop
              preload="auto"
              poster="data:image/gif,AAAA"
            />
          </div>
          {this.state.activeBreakpoint !== 's' &&
            this.state.videos.map((video, index) => {
              return (
                <VideoBG
                  ref={videoElem => {
                    this.videoOverRef.push(videoElem)
                  }}
                  key={index}
                  pose={video.showing ? 'visible' : 'hidden'}
                  style={{ opacity: 0 }}
                  className="hero__video-bg ef-fullbleed__bg"
                  playsInline
                  loop
                  src={video.url}
                  muted={true}
                  preload="auto"
                  poster="data:image/gif,AAAA"
                />
              )
            })}
        </div>
        <div className="ef-fullbleed__content">
          <div className="ef-container">
            <div className="ef-row">
              <div className="ef-col -m-12 -l-11 -xl-10 u-mt-xl">
                <h1
                  className={`u-mb-xs hero__video-title ${
                    this.state.videoOverPlaying ? '-video-playing' : ''
                  }`}
                >
                  <>
                    <span className="hero__video-title-content">
                      Home of everything
                    </span>{' '}
                    <span style={{ whiteSpace: 'nowrap' }}>
                      <Link
                        to="/work"
                        className={`hero__video-keyword ${
                          this.state.videos[0].showing ? '-video-playing' : ''
                        } `}
                        onMouseEnter={() => this._handleShowVideo(0)}
                        onMouseLeave={() => this._handleHideVideo(0)}
                      >
                        creative
                      </Link>
                      <span className="hero__video-title-content">,</span>
                    </span>{' '}
                    <Link
                      to="/work?tag=Digital"
                      className={`hero__video-keyword ${
                        this.state.videos[1].showing ? '-video-playing' : ''
                      } `}
                      onMouseEnter={() => this._handleShowVideo(1)}
                      onMouseLeave={() => this._handleHideVideo(1)}
                    >
                      digital
                    </Link>{' '}
                    <span className="hero__video-title-content">and</span>{' '}
                    <Link
                      to="/work?tag=Brand"
                      className={`hero__video-keyword ${
                        this.state.videos[2].showing ? '-video-playing' : ''
                      } `}
                      onMouseEnter={() => this._handleShowVideo(2)}
                      onMouseLeave={() => this._handleHideVideo(2)}
                    >
                      brand
                    </Link>
                    <span className="hero__video-title-content"> at EF.</span>
                  </>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

HeroHomepage.propTypes = {
  backgroundVideoUrl: PropTypes.string,
  backgroundVideoUrlMobile: PropTypes.string,
  modifiers: PropTypes.string,
}

HeroHomepage.defaultProps = {
  backgroundVideoUrl: '',
  backgroundVideoUrlMobile: '',
  modifiers: '',
}

export default HeroHomepage
