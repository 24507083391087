import React from 'react'
import Components from '../components.js'
import Layout from '../layout'
import Hero from '../Hero/hero'
import SEO from '../Seo/seo'
import HeroVideo from '../HeroVideo/hero-video'
import HeroHomepage from '../HeroHomepage/hero-homepage'

const Page = props => {
  const hero =
    props.blok.hero !== undefined &&
    props.blok.hero.length === 1 &&
    props.blok.hero[0]

  let headerModifiers = ''

  if (props.blok.header_type === 'transparent') {
    headerModifiers += ' -transparent'
    if (props.blok.header_gradient) {
      headerModifiers += ' -gradient'
    }
  }

  const renderHero = () => {
    if (hero) {
      switch (hero.component) {
        case 'hero-video':
          return (
            <HeroVideo
              backgroundVideoUrl={hero.background_video_url}
              backgroundVideoUrlMobile={hero.background_video_url_mobile}
              backgroundVideoPoster={hero.background_video_poster}
              modalVideoUrl={hero.video_url}
              modifiers={props.blok.header_gradient ? '-gradient' : ''}
              title={
                hero.title.trim().length ? hero.title.trim() : props.story.name
              }
              isHomepage={props.story.slug === 'home'}
            />
          )
        case 'hero-homepage':
          return (
            <HeroHomepage
              backgroundVideoUrl={hero.background_video_url}
              backgroundVideoUrlMobile={hero.background_video_url_mobile}
              backgroundVideoPoster={hero.background_video_poster}
              modifiers={props.blok.header_gradient ? '-gradient' : ''}
            />
          )
        case 'hero':
        default:
          return (
            <Hero
              focusPoint={hero.background_image}
              modifiers={props.blok.header_gradient ? '-gradient' : ''}
              title={
                hero.title.trim().length ? hero.title.trim() : props.story.name
              }
              tagList={props.story.tag_list}
            />
          )
      }
    }
    return null
  }

  return (
    <Layout settings={props.settings} headerModifiers={headerModifiers}>
      <SEO
        title={props.story.slug === 'home' ? 'EF Design' : props.story.name}
        pathname={props.story.slug === 'home' ? '' : props.story.full_slug}
      />
      {renderHero()}
      <div>
        {props.blok.body &&
          props.blok.body.map(blok =>
            React.createElement(Components[blok.component], {
              key: blok._uid,
              blok: blok,
            })
          )}
      </div>
    </Layout>
  )
}

export default Page
