import React from 'react'
import SbEditable from 'storyblok-react'
import { EfCarousel } from '@ef-global/web-ui/js/ef-carousel/ef-carousel'
import FocusPointImg from '../FocusPoint/focusPointImg'
import Link from '../Link/link'
import VideoLazy from '../VideoLazy/video-lazy'
import activeBreakpoint from '../../helpers/breakpoint'

class ImageAdvanced extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mobileBreakpoint: false,
    }

    this.slideRef = React.createRef()
    this.slider = false
  }

  componentDidMount() {
    this._handleResize()
    window.addEventListener('resize', this._handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleResize)
  }

  _handleResize = () => {
    const { show_on_mobile_as, stack_mobile } = this.props.blok

    this.resizeTimer && clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => {
      this.resizeTimer = null
      if (activeBreakpoint() === 's') {
        if (!this.state.mobileBreakpoint) {
          this.setState(
            {
              mobileBreakpoint: true,
            },
            () => {
              this.slider =
                show_on_mobile_as === 'carousel' &&
                !stack_mobile &&
                new EfCarousel(this.slideRef.current, {
                  breakpoints: {
                    640: {
                      slidesPerView: 'auto',
                      spaceBetween: 5,
                    },
                  },
                })
            }
          )
        }
      } else if (this.state.mobileBreakpoint) {
        this.setState({
          mobileBreakpoint: false,
        })
      }
    }, 300)
  }

  renderAsset(item) {
    const imageMaxSize = {
      width: 1600 / this.props.blok.images.length,
      height: 1600 / this.props.blok.images.length,
    }

    const aspectRatio =
      item.aspect_ratio === 'original'
        ? [item.image.imageSize.width, item.image.imageSize.height]
        : item.aspect_ratio !== ''
        ? item.aspect_ratio.split(':').map(Number)
        : [3, 2] //default aspect ratio

    const size = {
      width: Math.floor(imageMaxSize.width),
      height: Math.floor(
        imageMaxSize.height / (aspectRatio[0] / aspectRatio[1])
      ),
    }

    switch (item.component) {
      case 'focuspoint-image':
        return (
          <FocusPointImg
            focusPoint={item.image}
            size={size}
            alt={item.caption}
            className={`image-advanced__img ${
              !this.props.blok.disable_images_shadow
                ? 'ef-surface -deep-shadow -rounded'
                : ''
            }`}
          />
        )
      case 'video':
        const aspectRatioPercentage = aspectRatio[0] / aspectRatio[1]

        return (
          <div
            className="image-advanced__video-w"
            style={{
              paddingBottom: 100 / aspectRatioPercentage + '%',
            }}
          >
            <VideoLazy
              url={item.url}
              size={size}
              fallbackImage={item.fallback_image}
              caption={item.caption}
              className="image-advanced__video"
            />
          </div>
        )
      default:
        return null
    }
  }

  renderDefault = () => {
    const { show_on_mobile_as, stack_mobile, images } = this.props.blok
    const isStack = show_on_mobile_as === 'stack' || stack_mobile

    return (
      <div className={`image-advanced ${isStack ? '-stack-sm' : ''}`}>
        <div className="ef-row">
          {images.map(item => (
            <div
              className={`ef-col image-advanced__item ${
                isStack ? '-s-12 -m-auto u-s-mb-s u-m-mb-0' : ''
              }`}
              key={item._uid}
            >
              <div className="dip-setter">{this.renderAsset(item)}</div>
              {item.caption.trim().length > 0 &&
                (item.caption_link &&
                item.caption_link.cached_url.length > 0 ? (
                  <p className="image-advanced__caption">
                    <Link
                      storyblokLink={item.caption_link}
                      target={
                        item.caption_link.linktype === 'url'
                          ? '_blank'
                          : undefined
                      }
                    >
                      {item.caption}
                    </Link>
                  </p>
                ) : (
                  <p className="image-advanced__caption">{item.caption}</p>
                ))}
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderCarousel = () => (
    <div className="ef-slider" ref={this.slideRef}>
      <div className="ef-slider__inner">
        <div className="ef-slider__carousel swiper-container">
          <div className="swiper-wrapper">
            {this.props.blok.images &&
              this.props.blok.images.map(item => (
                <div className="swiper-slide" key={item._uid}>
                  {this.renderAsset(item)}
                  {item.caption.trim().length > 0 &&
                    (item.caption_link &&
                    item.caption_link.cached_url.length > 0 ? (
                      <p className="image-advanced__caption">
                        <Link
                          storyblokLink={item.caption_link}
                          target={
                            item.caption_link.linktype === 'url'
                              ? '_blank'
                              : undefined
                          }
                        >
                          {item.caption}
                        </Link>
                      </p>
                    ) : (
                      <p className="image-advanced__caption">{item.caption}</p>
                    ))}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="ef-slider__pagination" />
    </div>
  )

  render() {
    if (this.props.blok === undefined) return null
    let mobileRender

    if (this.props.blok.stack_mobile) {
      mobileRender = this.renderDefault()
    } else {
      switch (this.props.blok.show_on_mobile_as) {
        case 'carousel':
          mobileRender = this.renderCarousel()
          break
        case 'stack':
          mobileRender = this.renderDefault()
          break
        case 'default':
          mobileRender = this.renderDefault()
          break
        default:
          mobileRender = this.renderDefault()
          break
      }
    }

    return (
      <SbEditable content={this.props.blok}>
        {this.state.mobileBreakpoint ? mobileRender : this.renderDefault({})}
      </SbEditable>
    )
  }
}
export default ImageAdvanced
