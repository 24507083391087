import React from 'react'
import posed from 'react-pose'
import activeBreakpoint from '../../helpers/breakpoint'

const VideoBG = posed.video({
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 150 },
  },
})

class LocationsVideo extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.videoRef = React.createRef()

    this.state = {
      videoReady: false,
    }

    this._handleResize = this._handleResize.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    const videoElem = this.videoRef.current
    if (videoElem) {
      videoElem.muted = true
      videoElem.setAttribute('muted', 'true') //see more: https://github.com/facebook/react/issues/10389
      videoElem.setAttribute('autoplay', 'true')

      videoElem.oncanplay = () => {
        if (this._isMounted) {
          this.setState({ videoReady: true })
          this.playVideo()
        }
      }
    }

    this._handleResize()
    window.addEventListener('resize', this._handleResize)
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener('resize', this._handleResize)
  }

  _handleResize() {
    this.resizeTimer && clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => {
      this.resizeTimer = null
      if (
        activeBreakpoint() === 's' &&
        this.props.backgroundVideoUrlMobile.length > 0
      ) {
        // only update src if different from current to avoid reloading video
        if (this.videoRef.current.src !== this.props.backgroundVideoUrlMobile) {
          this.videoRef.current.src = this.props.backgroundVideoUrlMobile
        }
      } else {
        if (this.videoRef.current.src !== this.props.backgroundVideoUrl) {
          this.videoRef.current.src = this.props.backgroundVideoUrl
        }
      }
    })
  }

  playVideo() {
    if (this.videoRef.current) {
      this.videoRef.current.play()
    }
  }

  render() {
    return (
      <div className="location__video u-hide-s-only">
        <VideoBG
          ref={this.videoRef}
          pose={this.state.videoReady ? 'visible' : 'hidden'}
          className=""
          muted
          playsInline
          loop
          preload="auto"
          poster={this.props.backgroundVideoPoster}
        />
      </div>
    )
  }
}

export default LocationsVideo
