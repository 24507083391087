import React from 'react'
import SbEditable from 'storyblok-react'
import FocusPointImg from '../FocusPoint/focusPointImg'
import Link from '../Link/link'

const Team = props => {
  return (
    <SbEditable content={props.blok}>
      <section className="ef-section -s">
        <div className="ef-container">
          <div className="ef-row">
            <div className="ef-col">
              <Link storyblokLink={props.blok.link}>
                <FocusPointImg
                  focusPoint={props.blok.image}
                  size={{ width: 800, height: 532 }}
                  alt={props.blok.title}
                  className="ef-card--teaser__img image-advanced__radius"
                />
              </Link>
            </div>
          </div>
          <div className="ef-row u-s-pt-l u-m-pt-xxl u-m-pl-xxl u-m-pr-xxl">
            <div className="ef-col -s-12 -m-6">
              <h4 className="ef-h4">{props.blok.title}</h4>
              <Link
                className="ef-link--arrow u-show-m-up u-m-pt-s"
                storyblokLink={props.blok.link}
                arrow="true"
              >
                Meet the team
              </Link>
            </div>
            <div className="ef-col -s-12 -m-6">
              <p className="ef-text-body u-f-light">{props.blok.description}</p>
            </div>
            <div className="ef-col u-show-s-only u-s-pt-l">
              <Link
                className="ef-link--arrow"
                storyblokLink={props.blok.link}
                arrow="true"
              >
                Meet the team
              </Link>
            </div>
          </div>
        </div>
      </section>
    </SbEditable>
  )
}

export default Team
