import React from 'react'
import SbEditable from 'storyblok-react'
import { EfCarousel } from '@ef-global/web-ui/js/ef-carousel/ef-carousel'
import FocusPointImg from '../FocusPoint/focusPointImg'
import activeBreakpoint from '../../helpers/breakpoint'
import canUseWebp from '../../helpers/webpSupport'
import PhoneSilver from '../../images/Apple iPhone 8 Silver.png'
import PhoneSilverWebp from '../../images/Apple iPhone 8 Silver.webp'
import PhoneGrey from '../../images/Apple iPhone 8 Space Grey.png'
import PhoneGreyWebp from '../../images/Apple iPhone 8 Space Grey.webp'

class MobileGallery extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      carouselOnMobile: false,
    }
    this.slideRef = React.createRef()
    this.slider = false

    this._handleResize = this._handleResize.bind(this)
  }

  componentDidMount() {
    this._handleResize()
    window.addEventListener('resize', this._handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleResize)
  }

  render() {
    const mobileStructureBgImgStyle = blok => {
      return {
        backgroundImage:
          blok.type === 'black'
            ? `url('${canUseWebp ? PhoneGreyWebp : PhoneGrey}')`
            : `url('${canUseWebp ? PhoneSilverWebp : PhoneSilver}')`,
      }
    }

    return (
      <div className="mobile-gallery">
        <SbEditable content={this.props.blok}>
          {this.state.carouselOnMobile ? (
            <div className="ef-slider" ref={this.slideRef}>
              <div className="ef-slider__inner">
                <div className="ef-slider__carousel swiper-container">
                  <div className="swiper-wrapper">
                    {this.props.blok.items &&
                      this.props.blok.items.map(blok => (
                        <div className="swiper-slide" key={blok._uid}>
                          <div
                            className="mobile-gallery__item"
                            style={mobileStructureBgImgStyle(blok)}
                          >
                            {blok.image && blok.image.image.length > 0 && (
                              <FocusPointImg
                                focusPoint={blok.image}
                                size={{ width: 309, height: 546 }}
                                alt="test"
                                className="mobile-gallery__img"
                              />
                            )}
                          </div>
                          <p className="mobile-gallery__caption">
                            {blok.caption}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="ef-slider__pagination" />
            </div>
          ) : (
            <div className="ef-row -x-center">
              {this.props.blok.items &&
                this.props.blok.items.map(blok => (
                  <div className="ef-col -m-4 -s-12" key={blok._uid}>
                    <div
                      className="mobile-gallery__item"
                      style={mobileStructureBgImgStyle(blok)}
                    >
                      {blok.image && blok.image.image.length > 0 ? (
                        <FocusPointImg
                          focusPoint={blok.image}
                          size={{ width: 290, height: 512 }}
                          alt="test"
                          className="mobile-gallery__img"
                        />
                      ) : (
                        <div className="mobile-gallery__img u-text-center">
                          Please add an image
                        </div>
                      )}
                    </div>
                    <p className="mobile-gallery__caption">{blok.caption}</p>
                  </div>
                ))}
            </div>
          )}
        </SbEditable>
      </div>
    )
  }

  _handleResize() {
    this.resizeTimer && clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => {
      this.resizeTimer = null
      if (activeBreakpoint() === 's') {
        if (!this.props.blok.stack_mobile && !this.state.carouselOnMobile) {
          this.setState(
            {
              carouselOnMobile: true,
            },
            () => {
              this.slider = new EfCarousel(this.slideRef.current, {
                breakpoints: {
                  640: {
                    slidesPerView: 'auto',
                    spaceBetween: 5,
                  },
                },
              })
            }
          )
        }
      } else if (this.state.carouselOnMobile) {
        this.setState({
          carouselOnMobile: false,
        })
      }
    }, 300)
  }
}

export default MobileGallery
