import React from 'react'
import Components from '../components.js'
import SbEditable from 'storyblok-react'

const Section = props => {
  let paddingTopClass = ''

  switch (props.blok.padding_top) {
    case 'collapse':
      paddingTopClass = 'u-pt-0'
      break
    case 'gutter':
      paddingTopClass = 'u-s-pt-s u-l-pt-m'
      break
    case 'small':
      paddingTopClass = '-pt-s'
      break
    case 'large':
      paddingTopClass = '-pt-l'
      break
    default:
  }

  let paddingBottomClass = ''
  switch (props.blok.padding_bottom) {
    case 'collapse':
      paddingBottomClass = 'u-pb-0'
      break
    case 'gutter':
      paddingBottomClass = 'u-s-pb-s u-l-pb-m'
      break
    case 'small':
      paddingBottomClass = '-pb-s'
      break
    case 'large':
      paddingBottomClass = '-pb-l'
      break
    default:
  }

  let componentsVerticalSpacingClass = ''
  switch (props.blok.components_vertical_spacing) {
    case 'small':
      componentsVerticalSpacingClass = '-components-s'
      break
    case 'gutter':
      componentsVerticalSpacingClass = '-components-gutter'
      break
    case 'medium':
      componentsVerticalSpacingClass = '-components-m'
      break
    case 'large':
      componentsVerticalSpacingClass = '-components-l'
      break
    case 'collapse':
    default:
      componentsVerticalSpacingClass = ''
  }

  return (
    <SbEditable content={props.blok}>
      <section
        className={`ef-section ${paddingTopClass} ${paddingBottomClass} ${
          props.blok.dip ? '-dip' : ''
        }
        ${componentsVerticalSpacingClass}
        ${props.blok.text_color === 'light' ? '-inv' : ''}`}
        style={{ backgroundColor: props.blok.background.color }}
      >
        <div className={`${!props.blok.full_width ? 'ef-container' : ''}`}>
          {props.blok.content !== undefined &&
            props.blok.content.map(blok => {
              return (
                <div
                  className={`ef-section__component ${
                    props.blok.full_width ? '-full-width' : ''
                  }`}
                  key={blok._uid}
                >
                  {React.createElement(Components[blok.component], {
                    blok: blok,
                  })}
                </div>
              )
            })}
        </div>
      </section>
    </SbEditable>
  )
}

export default Section
