import React, { useEffect, useState } from 'react'
import Components from '../components'
import Layout from '../layout'
import SEO from '../Seo/seo'
import SbEditable from 'storyblok-react'

const shuffle = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

const Teams = props => {
  const [shuffledTeams, setShuffledTeams] = useState(false)

  useEffect(() => {
    setShuffledTeams(shuffle(props.blok.body))
  }, [props.blok.body])

  let headerModifiers = ''
  return (
    <Layout settings={props.settings} headerModifiers={headerModifiers}>
      <SEO
        title={props.story.slug === 'home' ? 'EF Design' : props.story.name}
        pathname={props.story.slug === 'home' ? '' : props.story.full_slug}
      />

      <section className="ef-section -m">
        {props.blok.header && (
          <SbEditable content={props.blok.header[0]}>
            <div className="ef-container">
              <div className="ef-row">
                <div className="ef-col -m-5">
                  <h3 className="ef-h3">{props.blok.header[0].title}</h3>
                  <p className="ef-text-body u-f-light">
                    {props.blok.header[0].text}
                  </p>
                </div>
              </div>
            </div>
          </SbEditable>
        )}

        {shuffledTeams &&
          shuffledTeams.map(blok =>
            React.createElement(Components[blok.component], {
              key: blok._uid,
              blok: blok,
            })
          )}
      </section>
    </Layout>
  )
}

export default Teams
