import React from 'react'
import { graphql, StaticQuery, Link, navigate } from 'gatsby'
import posed, { PoseGroup } from 'react-pose'

import parseStoryFromNode from '../../helpers/parseStory'
import FocusPointImg from '../FocusPoint/focusPointImg'

const storiesQuery = graphql`
  query {
    allStoryblokEntry(
      filter: { full_slug: { regex: "/work/.*/" }, is_startpage: { eq: false } }
    ) {
      edges {
        node {
          id
          name
          created_at
          published_at
          uuid
          slug
          full_slug
          content
          is_startpage
          parent_id
          group_id
          tag_list
        }
      }
    }
  }
`

const Item = posed.div({
  flip: {
    transition: {
      type: 'tween',
      ease: 'easeOut',
    },
  },
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
})

class BlockList extends React.Component {
  constructor(props) {
    super(props)

    this._handleFilterClick = this._handleFilterClick.bind(this)
    this.state = { activeTag: 'All' }
  }

  componentDidMount() {
    const queryParams = this._getParams(window.location)

    const activeTag = queryParams.tag ? queryParams.tag : 'All'
    this.setState({ activeTag })
  }

  _handleFilterClick(tag) {
    navigate(`/work?tag=${tag}`, { replace: true })

    this.setState({ activeTag: tag })
  }

  _getParams(location) {
    const searchParams = new URLSearchParams(location.search)
    return {
      tag: searchParams.get('tag') || '',
    }
  }

  render() {
    return (
      <StaticQuery
        query={storiesQuery}
        render={data => {
          const stories = data.allStoryblokEntry.edges
            .map(story => parseStoryFromNode(story))
            .sort((a, b) => {
              const date1 = new Date(a.published_at)
              const date2 = new Date(b.published_at)
              return date1 - date2
            })
            .reverse()

          const tags = ['All']
          stories.forEach(story => {
            story.tag_list.forEach(tag => {
              if (tags.indexOf(tag) === -1) {
                tags.push(tag)
              }
            })
          })

          const filteredStories = stories.filter(
            story =>
              story.tag_list.includes(this.state.activeTag) ||
              this.state.activeTag === 'All'
          )

          return (
            <section className="ef-container">
              <div className="ef-section">
                <div className="ef-row -y-end -x-end">
                  <div className="ef-col -s-12 -m-5">
                    <h4 className="ef-h3">{this.props.blok.Title}</h4>
                    <p className="ef-text-body u-f-light">
                      {this.props.blok.Intro}
                    </p>
                  </div>
                  <div className="ef-col block-list__items">
                    {tags.map(tag => {
                      return (
                        <button
                          className={`ef-tag -button block-list__tag u-s-mr-s u-m-ml-s u-m-mr-0 u-mb-s ${this
                            .state.activeTag === tag && '-active'}`}
                          key={tag}
                          onClick={() => this._handleFilterClick(tag)}
                        >
                          {tag}
                        </button>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="ef-row block-list__content">
                <PoseGroup animateOnMount={true}>
                  {filteredStories.map(story => {
                    const { hero_gradients } = story.content

                    return (
                      <Item
                        className="ef-col -s-12 -m-6 -l-4 u-mb-s u-l-mb-m"
                        key={story.uuid}
                      >
                        <Link
                          to={`/${story.full_slug}`}
                          className={`ef-card--teaser ${!hero_gradients &&
                            '-no-shadow'}  block-list__item`}
                        >
                          {story.content.featured_image && (
                            <FocusPointImg
                              focusPoint={story.content.featured_image}
                              size={{ width: 384, height: 430 }}
                              alt={story.name}
                              className="ef-card--teaser__img"
                            />
                          )}
                          <div className="ef-card--teaser__inner">
                            <div className="ef-card--teaser__content">
                              <div className="ef-card--teaser__text">
                                {story.tag_list.join(', ')}
                              </div>
                              <h4 className="ef-card--teaser__title">
                                {story.name}
                              </h4>
                            </div>
                          </div>
                        </Link>
                      </Item>
                    )
                  })}
                </PoseGroup>
              </div>
            </section>
          )
        }}
      />
    )
  }
}

export default BlockList
