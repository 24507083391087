import React from 'react'
import Components from '../components.js'
import SbEditable from 'storyblok-react'

const Row = props => {
  let alignmentClass = ''
  switch (props.blok.columns_alignment) {
    case 'right':
      alignmentClass = '-x-end'
      break
    case 'center':
      alignmentClass = '-x-center'
      break
    default:
  }
  return (
    <SbEditable content={props.blok}>
      <div className={`ef-row ${alignmentClass}`}>
        {props.blok.columns.map(blok =>
          React.createElement(Components[blok.component], {
            key: blok._uid,
            blok: blok,
          })
        )}
      </div>
    </SbEditable>
  )
}

export default Row
