import React from 'react'
import SbEditable from 'storyblok-react'
import FocusPointBg from '../FocusPoint/focusPointBg'
import moment from 'moment'

const JournalCard = props => {
  return (
    <SbEditable content={props.blok}>
      <a
        className={`journal-card ${props.blok.type === 'box' ? '-box' : ''}`}
        href={props.blok.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FocusPointBg
          focusPoint={props.blok.image}
          size={{ width: 400, height: 400 }}
          className="journal-card__bg"
          tabIndex="-1"
        />

        <div className="journal-card__content">
          <div className="journal-card__top-info">
            <span className="journal-card__author">{props.blok.author}</span>
            <span className="journal-card__date">
              {moment(props.blok.date).format('MMM DD, YYYY')}
            </span>
          </div>
          <h5 className="journal-card__title">{props.blok.title}</h5>
          <span className={`journal-card__source-icon -${props.blok.source}`} />
        </div>
      </a>
    </SbEditable>
  )
}

export default JournalCard
