import React from 'react'

import Location from '../LocationFolder/location'
import Layout from '../layout'
import LocationVideo from '../LocationFolder/locations-video'
import SEO from '../Seo/seo'

const LocationIndex = props => {
  return (
    <Layout settings={props.settings} headerModifiers="">
      <SEO
        title={props.story.name}
        pathname={props.story.full_slug}
        description={props.blok.intro}
      />
      <div className="ef-section u-m-pb-0">
        <div className="ef-container">
          <div className="ef-row">
            <div className="ef-col -s-12 -m-4">
              <div className="locations-title-wrapper">
                <h4 className="ef-h3">Explore our Locations</h4>
                <p className="ef-text u-f-light">{props.blok.intro}</p>
              </div>
            </div>
            <div className="ef-col -s-12 -m-6 -m-offset-2">
              <LocationVideo
                modifiers={'-gradient'}
                title={props.story.name}
                backgroundVideoUrl={props.blok.hero_video_url}
                backgroundVideoUrlMobile={props.blok.hero_video_url_mobile}
                backgroundVideoPoster={props.blok.hero_video_poster}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="ef-section u-s-pt-0 u-m-pt-xxl">
        <div className="ef-container">
          {props.blok.locations &&
            props.blok.locations.map((blok, index) => (
              <Location blok={blok} key={blok._uid} locationIndex={index} />
            ))}
        </div>
      </div>
    </Layout>
  )
}

export default LocationIndex
