import React from 'react'
import PropTypes from 'prop-types'
import ScrollTrigger from 'react-scroll-trigger'

import FocusPointBg from '../FocusPoint/focusPointBg'
import activeBreakpoint from '../../helpers/breakpoint'

class Hero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeBreakpoint: '',
      heroBgZoom: 1,
    }

    this._handleResize = this._handleResize.bind(this)
    this.onScrollProgress = this.onScrollProgress.bind(this)
  }

  componentDidMount() {
    this._handleResize()
    window.addEventListener('resize', this._handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleResize)
  }

  onScrollProgress({ progress }) {
    //console.log(progress)
    const newProgress = (progress - 0.5) * 0.3 + 1
    this.setState({ heroBgZoom: newProgress > 1 ? newProgress : 1 })
  }

  _handleResize() {
    this.resizeTimer && clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => {
      this.resizeTimer = null

      this.setState({ activeBreakpoint: activeBreakpoint() })
    }, 300)
  }

  render() {
    return (
      <div className={`ef-fullbleed hero ${this.props.modifiers}`} id="hero">
        {this.state.activeBreakpoint &&
          this.props.focusPoint && this.props.focusPoint.image.length && (
            <ScrollTrigger
              onProgress={this.onScrollProgress}
              className="ef-fullbleed__bg-w -animate"
              style={{
                transform: `translate3d(0,0,0) scale(${this.state.heroBgZoom})`,
              }}
            >
              {this.state.activeBreakpoint === 's' ? (
                <FocusPointBg
                  className="ef-fullbleed__bg"
                  focusPoint={this.props.focusPoint}
                  size={{ width: 600, height: 800 }}
                />
              ) : (
                <FocusPointBg
                  className="ef-fullbleed__bg"
                  focusPoint={this.props.focusPoint}
                  size={{ width: 2000, height: 0 }}
                />
              )}
            </ScrollTrigger>
          )}
        <div className="ef-fullbleed__content">
          <div className="ef-container">
            <div className="ef-row -y-end">
              <div className="ef-col -s-shrink -m-8 -l-6 -s-y-center u-mt-xl">
                {this.props.tagList !== undefined && (
                  <p className="hero__tags">
                    {this.props.tagList.sort().join(', ')}
                  </p>
                )}
                <h1 className="ef-h2 u-text-light u-mb-0">
                  {this.props.title}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Hero.propTypes = {
  title: PropTypes.string,
  tagList: PropTypes.array,
  focusPoint: PropTypes.object,
  modifiers: PropTypes.string,
}

export default Hero
