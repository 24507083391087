import React from 'react'
import SbEditable from 'storyblok-react'

import FocusPointBg from '../FocusPoint/focusPointBg'

const Fullbleed = props => {
  let sizeClass = ''
  switch (props.blok.size) {
    case 'small':
      sizeClass = '-small'
      break
    case 'large':
      sizeClass = '-large'
      break
    default:
  }

  return (
    <SbEditable content={props.blok}>
      <div className={`ef-fullbleed fullbleed ${sizeClass}`}>
        {props.blok.image && props.blok.image.image.length ? (
          <FocusPointBg
            className="ef-fullbleed__bg"
            focusPoint={props.blok.image}
            size={{ width: 2000, height: 800 }}
          />
        ) : (
          <div className="ef-fullbleed__bg ef-fullbleed__bg--placeholder">
            <div className="u-xy-abs-center">
              Please select a background image
            </div>
          </div>
        )}
      </div>
    </SbEditable>
  )
}

export default Fullbleed
