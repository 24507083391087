import React from 'react'
import PropTypes from 'prop-types'
import posed from 'react-pose'
import Modal from 'react-modal'
import ReactPlayer from 'react-player'

import activeBreakpoint from '../../helpers/breakpoint'
import BgImgLazyLoad from '../bgImgLazyLoad'
import { resize } from '../../helpers/imageService'

const VideoBG = posed.video({
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 150 },
  },
})

class HeroVideo extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.videoRef = React.createRef()

    this.state = {
      videoReady: false,
      modalIsOpen: false,
    }

    this._handleOpenModal = this._handleOpenModal.bind(this)
    this._handleCloseModal = this._handleCloseModal.bind(this)
    this._handleResize = this._handleResize.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    const videoElem = this.videoRef.current
    if (videoElem) {
      videoElem.muted = true
      videoElem.setAttribute('muted', 'true') //see more: https://github.com/facebook/react/issues/10389
      videoElem.setAttribute('autoplay', 'true')

      videoElem.oncanplay = () => {
        if (this._isMounted) {
          this.setState({ videoReady: true })
          this.playVideo()
        }
      }
    }

    this._handleResize()
    window.addEventListener('resize', this._handleResize)
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener('resize', this._handleResize)
  }

  _handleResize() {
    this.resizeTimer && clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => {
      this.resizeTimer = null
      if (
        activeBreakpoint() === 's' &&
        this.props.backgroundVideoUrlMobile.length > 0
      ) {
        // only update src if different from current to avoid reloading video
        if (this.videoRef.current.src !== this.props.backgroundVideoUrlMobile) {
          this.videoRef.current.src = this.props.backgroundVideoUrlMobile
        }
      } else {
        if (this.videoRef.current.src !== this.props.backgroundVideoUrl) {
          this.videoRef.current.src = this.props.backgroundVideoUrl
        }
      }
    })
  }

  playVideo() {
    if (this.videoRef.current) {
      this.videoRef.current.play()
    }
  }

  _handleOpenModal() {
    this.setState({ modalIsOpen: true })
  }

  _handleCloseModal() {
    this.setState({ modalIsOpen: false })
  }

  render() {
    return (
      <div className={`ef-fullbleed hero -video`} id="hero">
        {this.props.backgroundVideoUrl.length > 0 && (
          <div className="hero__video-wrap">
            <BgImgLazyLoad
              imgSrc={resize(this.props.backgroundVideoPoster, '')}
              render={({ image }) =>
                image && (
                  <div
                    className="hero__video-poster focuspoint__load-animation"
                    style={{
                      backgroundImage: `url('${image}')`,
                    }}
                  />
                )
              }
            />

            <VideoBG
              ref={this.videoRef}
              pose={this.state.videoReady ? 'visible' : 'hidden'}
              className="hero__video-bg ef-fullbleed__bg"
              muted
              playsInline
              loop
              preload="auto"
              poster="data:image/gif,AAAA"
            />
          </div>
        )}
        <div className="ef-fullbleed__content">
          <div className="ef-container">
            <div className="ef-row -y-end">
              <div className="ef-col -m-10 -s-y-center u-mt-xl">
                <h1 className="u-text-light u-mb-xs hero__video-title">
                  {this.props.title}
                </h1>
              </div>
            </div>
          </div>
        </div>
        {this.props.modalVideoUrl.length > 0 && (
          <div>
            <button
              className="hero__video-btn"
              onClick={this._handleOpenModal}
              aria-label="Play video"
            >
              <span className="ef-icon -play" />
            </button>
            <Modal
              ariaHideApp={false}
              className="modal"
              overlayClassName="modal__overlay"
              onRequestClose={this._handleCloseModal}
              isOpen={this.state.modalIsOpen}
              closeTimeoutMS={150}
            >
              <div className="modal__wrap">
                <div className="ef-container">
                  <div className="modal__content">
                    <div className="modal__player-wrap">
                      <ReactPlayer
                        url={this.props.modalVideoUrl}
                        playing
                        className="modal__player"
                        width="100%"
                        height="100%"
                        controls
                      />
                      <button
                        className="modal-close"
                        onClick={this._handleCloseModal}
                      >
                        <span className="ef-icon -close -light" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    )
  }
}

HeroVideo.propTypes = {
  title: PropTypes.string,
  backgroundVideoUrl: PropTypes.string,
  backgroundVideoUrlMobile: PropTypes.string,
  backgroundVideoPoster: PropTypes.string,
  modalVideoUrl: PropTypes.string,
}

HeroVideo.defaultProps = {
  backgroundVideoUrl: '',
  backgroundVideoUrlMobile: '',
  backgroundVideoPoster: '',
  modalVideoUrl: '',
}

export default HeroVideo
