import React from 'react'
import Components from '../components.js'
import ReactMarkdown from 'react-markdown'
import Layout from '../layout'
import SEO from '../Seo/seo'
import SbEditable from 'storyblok-react'
import SocialContainer from '../SocialContainer/social-container'

const TeamPage = props => {
  let headerModifiers = ''
  return (
    <Layout settings={props.settings} headerModifiers={headerModifiers}>
      <SEO
        title={props.story.slug === 'home' ? 'EF Design' : props.story.name}
        pathname={props.story.slug === 'home' ? '' : props.story.full_slug}
      />
      <section className="ef-section u-s-pb-0 u-m-pb-s">
        <div className="ef-container">
          {props.blok.header &&
            props.blok.header.map(header => (
              <SbEditable content={header}>
                <div className="ef-row">
                  <div className="ef-col -m-5">
                    <h4 className="ef-h4">{header.title}</h4>
                    <p>
                      <span className="ef-icon -location-outline" />
                      <a
                        className="u-s-pl-xs"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/locations#${header.location}`}
                      >
                        {header.location}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="ef-row">
                  <div className="ef-col -s-12 -m-5 u-s-pt-s">
                    <ReactMarkdown
                      source={header.text}
                      className="ef-text-body ef-teams-header u-f-light"
                    />
                  </div>
                  <div className="ef-col -s-12 -m-5 -m-offset-2 u-s-pt-s">
                    {props.blok.socials && (
                      <SocialContainer socials={props.blok.socials} />
                    )}
                  </div>
                </div>
              </SbEditable>
            ))}
        </div>
      </section>
      <section className="ef-section u-s-pt-s u-l-pt-s">
        {props.blok.body &&
          props.blok.body.map(blok =>
            React.createElement(Components[blok.component], {
              key: blok._uid,
              blok: blok,
            })
          )}
      </section>
    </Layout>
  )
}

export default TeamPage
