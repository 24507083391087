import React from 'react'
import SbEditable from 'storyblok-react'

const Snippet = props => (
  <SbEditable content={props.blok}>
    <div
      className={`ef-col -s-${props.blok.mobile_columns} -m-${props.blok.desktop_columns} u-mb-l`}
    >
      <h3 className="ef-h4 u-mb-0">{props.blok.title}</h3>
      <p className="ef-text-para -light">{props.blok.description}</p>
    </div>
  </SbEditable>
)

export default Snippet
