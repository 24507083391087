import React from 'react'
import SbEditable from 'storyblok-react'

const socialShorthands = {
  pinterest: 'pi',
  instagram: 'in',
  behance: 'be',
}

export default class SocialContainer extends React.Component {
  chunkeList = () => {
    const { socials } = this.props

    let chunkedList = []
    let i = 2
    do {
      const slicedList = socials.splice(0, i)
      chunkedList = [...chunkedList, [...slicedList]]
    } while (socials.length > 0)

    return chunkedList
  }

  renderList = () => {
    const chunkedList = this.chunkeList()

    return chunkedList.map((item, index) => (
      <div key={index} className="ef-row u-s-mb-s u-m-mb-l">
        {item.map(
          socialAccount =>
            socialAccount.links.length > 0 && (
              <div key={socialAccount._uid} className="ef-col -s-12">
                <div>
                  <ul className="social-container__list u-s-mt-xs">
                    {socialAccount.links.map(link => (
                      <li key={link._uid} className="social-list">
                        <span
                          className={`ef-icon__inline -social-${
                            socialShorthands[socialAccount.name]
                          }`}
                        />
                        <a
                          href={link.link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link.displayName}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )
        )}
      </div>
    ))
  }

  render() {
    const { socials } = this.props

    return <SbEditable content={socials}>{this.renderList(socials)}</SbEditable>
  }
}
