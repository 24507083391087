import React from 'react'
import SbEditable from 'storyblok-react'
import Snippet from './snippet'

const SnippetList = props => (
  <SbEditable content={props.blok}>
    <div className="snippet-list">
      <div className="ef-row">
        {props.blok.items &&
          props.blok.items.map(blok => <Snippet blok={blok} key={blok._uid} />)}
      </div>
    </div>
  </SbEditable>
)

export default SnippetList
