// content types
import Page from './content-types/page'
import LocationIndex from './content-types/location-index'

// nestable components
import JournalIndex from './JournalIndex/journal-index'
import TextBlock from './TextBlock/text-block'
import SimpleTextBlock from './SimpleTextBlock/simple-text-block'
import CTABlock from './CTABlock/cta-block'
import CTASimple from './CTASimple/cta-simple'
import SocialContainer from './SocialContainer/social-container'
import Image from './Image/image'
import Hero from './Hero/hero'
import Fullbleed from './Fullbleed/fullbleed'
import VideoCard from './VideoCard/video-card'
import ImageAdvanced from './ImageAdvanced/image-advanced'
import Row from './Row/row'
import Project from './project/project'
import MobileGallery from './MobileGallery/mobile-gallery'
import BlockList from './BlockList/block-list'
import SnippetList from './Snippet/snippet-list'
import Section from './Section/section'
import Carousel from './Carousel/carousel'
import Column from './Column/column'
import Card from './Card/card'
import Markdown from './Markdown/markdown'
import Teams from './Teams/teams'
import Team from './Teams/team'
import TeamPage from './Teams/team-page'

export default {
  //Page templates
  page: Page,
  project: Project,
  'location-index': LocationIndex,
  'journal-index': JournalIndex,
  teams: Teams,
  'team-page': TeamPage,
  //Nestable components
  section: Section,
  row: Row,
  column: Column,
  carousel: Carousel,
  card: Card,
  'block-list': BlockList,
  hero: Hero,
  markdown: Markdown,
  'image-advanced': ImageAdvanced,
  'text-block': TextBlock,
  'simple-text-block': SimpleTextBlock,
  'video-card': VideoCard,
  'mobile-gallery': MobileGallery,
  fullbleed: Fullbleed,
  'snippet-list': SnippetList,
  'CTA-block': CTABlock,
  socialContainer: SocialContainer,
  'focuspoint-image': Image,
  team: Team,
  'CTA-simple': CTASimple,
}
