import React from 'react'
import SbEditable from 'storyblok-react'
import ReactMardown from 'react-markdown'

const SimpleTextBlock = props => {
  const TitleTag = `h${props.blok.title_size}`

  return (
    <SbEditable content={props.blok}>
      <div className={`text-block`}>
        {props.blok.title && (
          <TitleTag
            className={props.blok.title_weight === 'normal' ? 'u-f-book' : null}
          >
            {props.blok.title}
          </TitleTag>
        )}
        <div className={props.blok.text_weight === 'light' ? 'u-f-light' : ''}>
          <ReactMardown source={props.blok.content} className="markdown" />
        </div>
      </div>
    </SbEditable>
  )
}

export default SimpleTextBlock
