import PropTypes from 'prop-types'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import canUseWebp from '../../helpers/webpSupport'
import { useIsInEditorHook } from '../../helpers/hooks'
import { resizeWithFocusPoint } from '../../helpers/imageService'

const FocusPointImg = props => {
  const isInEditor = useIsInEditorHook()
  const { focusPoint, size, alt, className, ...attributes } = props
  const resizedImage = resizeWithFocusPoint(
    focusPoint.image,
    focusPoint.imageSize,
    focusPoint.focusPoint,
    size
  )

  const aspectRatio = resizedImage.size.width / resizedImage.size.height

  return (
    <div
      className="focuspoint__img-w"
      style={{ paddingBottom: 100 / aspectRatio + '%' }}
    >
      {resizedImage.url !== '' &&
        (isInEditor ? (
          <img
            src={resizedImage.url}
            alt={alt}
            className={`${className}`}
            {...attributes}
          />
        ) : (
          <>
            <LazyLoadImage
              src={canUseWebp() ? resizedImage.urlWebp : resizedImage.url}
              threshold="300"
              placeholder={<div />}
              effect="opacity"
              alt={alt}
              className={`${className}`}
              {...attributes}
            />
            <noscript>
              <img
                className={`${className}`}
                alt={alt}
                src={resizedImage.url}
                {...attributes}
              />
            </noscript>
          </>
        ))}
    </div>
  )
}

FocusPointImg.propTypes = {
  focusPoint: PropTypes.object.isRequired,
  size: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
}

export default FocusPointImg
