import React from 'react'
import Components from '../components.js'
import Layout from '../layout'
import ProjectIntro from './project-intro'
import RelatedProjects from './related-projects'

const Project = props => {
  const headerModifiers = `-transparent ${
    props.blok.hero_gradients ? ' -gradient' : ''
  }`

  return (
    <Layout settings={props.settings} headerModifiers={headerModifiers}>
      <ProjectIntro {...props} />
      <div>
        {props.blok.body &&
          props.blok.body.map(blok =>
            React.createElement(Components[blok.component], {
              key: blok._uid,
              blok: blok,
            })
          )}
      </div>
      <RelatedProjects story={props.story} />
    </Layout>
  )
}

export default Project
