import React from 'react'
import SbEditable from 'storyblok-react'
import ReactMarkdown from 'react-markdown'

const TextBlock = props => {
  const subtitle1 =
    props.blok.subtitle_1 && props.blok.subtitle_1.length > 0
      ? props.blok.subtitle_1
      : ''
  const subtitle2 =
    props.blok.subtitle_2 && props.blok.subtitle_2.length > 0
      ? props.blok.subtitle_2
      : ''
  const text1 =
    props.blok.text_1 && props.blok.text_1.length > 0 ? props.blok.text_1 : ''
  const text2 =
    props.blok.text_2 && props.blok.text_2.length > 0 ? props.blok.text_2 : ''

  const linkTarget = url => {
    if (url.match('http')) {
      return '_blank'
    }
    return undefined
  }

  const content = () => {
    switch (props.blok.type) {
      case '2_cols':
        return (
          <div>
            <div className="ef-row">
              <div className="ef-col">
                <h3 className="u-mb-xxl">{props.blok.title}</h3>
              </div>
            </div>
            <div className="ef-row">
              <div className="ef-col -s-12 -m-6">
                {subtitle1.length > 0 && (
                  <h4 className="u-mb-xl">{subtitle1}</h4>
                )}
              </div>
              <div className="ef-col -s-12 -m-6 text-block--2cols__text">
                {text1.length > 0 && (
                  <ReactMarkdown
                    source={text1}
                    className={`markdown ${props.blok.text_size === 'large' &&
                      '-large'}`}
                    linkTarget={url => linkTarget(url)}
                  />
                )}
              </div>
              <div className="ef-col -s-12 -m-6 u-s-mt-xl u-m-mt-0">
                {subtitle2.length > 0 && <h4>{subtitle2}</h4>}
              </div>
              <div className="ef-col -s-12 -m-6 text-block--2cols__text">
                {text2.length > 0 && (
                  <ReactMarkdown
                    source={text2}
                    className={`markdown ${props.blok.text_size === 'large' &&
                      '-large'}`}
                    linkTarget={url => linkTarget(url)}
                  />
                )}
              </div>
            </div>
          </div>
        )
      case 'side_by_side':
        return (
          <div className="ef-row">
            <div className="ef-col -s-12 -m-4">
              <h3>{props.blok.title}</h3>
            </div>
            <div className="ef-col -s-12 -m-7 -m-offset-1">
              {subtitle1.length > 0 && <h4 className="u-mb-l">{subtitle1}</h4>}
              {text1.length > 0 && (
                <ReactMarkdown
                  source={text1}
                  className={`markdown u-mb-l ${props.blok.text_size ===
                    'large' && '-large'}`}
                  linkTarget={url => linkTarget(url)}
                />
              )}
              {subtitle2.length > 0 && <h4 className="u-mb-l">{subtitle2}</h4>}
              {text2.length > 0 && (
                <ReactMarkdown
                  source={text2}
                  className={`markdown ${props.blok.text_size === 'large' &&
                    '-large'}`}
                  linkTarget={url => linkTarget(url)}
                />
              )}
            </div>
          </div>
        )
      default:
        return (
          <div className="ef-row">
            <div className="ef-col">
              <h3>{props.blok.title}</h3>
              {subtitle1.length > 0 && <h4 className="u-mb-l">{subtitle1}</h4>}
              {text1.length > 0 && (
                <ReactMarkdown
                  source={text1}
                  className={`markdown u-mb-l ${props.blok.text_size ===
                    'large' && '-large'}`}
                  linkTarget={url => linkTarget(url)}
                />
              )}
              {subtitle2.length > 0 && <h4 className="u-mb-l">{subtitle2}</h4>}
              {text2.length > 0 && (
                <ReactMarkdown
                  source={text2}
                  className={`markdown ${props.blok.text_size === 'large' &&
                    '-large'}`}
                  linkTarget={url => linkTarget(url)}
                />
              )}
            </div>
          </div>
        )
    }
  }

  return (
    <SbEditable content={props.blok}>
      <div className="text-block">{content()}</div>
    </SbEditable>
  )
}

export default TextBlock
