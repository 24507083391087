import React from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { resizeWithFocusPoint } from '../../helpers/imageService'

class VideoLazy extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.videoRef = React.createRef()

    this.state = {
      videoReady: false,
    }

    this.loadVideo = this.loadVideo.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  loadVideo() {
    const videoElem = this.videoRef.current
    if (videoElem) {
      videoElem.muted = true
      videoElem.setAttribute('muted', 'true') //see more: https://github.com/facebook/react/issues/10389
      videoElem.setAttribute('autoplay', 'true')

      videoElem.oncanplay = () => {
        if (this._isMounted) {
          if (videoElem) {
            this.setState({ videoReady: true })
            videoElem.play()
          }
        }
      }
    }
  }

  render() {
    // we have to pass placeholder here with some height/width, otherwise,
    // react-lazy-load-image-component will not handle lazy loading properly
    // (while default, they initialize it  without dimensions) which leads to problems
    const placeholder = <section style={{ height: '1px', width: '1px' }} />

    return (
      <LazyLoadComponent afterLoad={this.loadVideo} placeholder={placeholder}>
        <video
          ref={this.videoRef}
          playsInline
          muted
          loop
          preload="auto"
          poster="data:image/gif,AAAA"
          className={`${this.props.className} ${
            this.state.videoReady ? '-ready' : ''
          }`}
        >
          <source src={this.props.url} />
          {this.props.fallbackImage && (
            <img
              src={
                resizeWithFocusPoint(
                  this.props.fallbackImage.image,
                  this.props.fallbackImage.imageSize,
                  this.props.fallbackImage.focusPoint,
                  this.props.size
                ).url
              }
              alt={this.props.caption}
            />
          )}
        </video>
      </LazyLoadComponent>
    )
  }
}

export default VideoLazy
