import React from 'react'
import SbEditable from 'storyblok-react'
import ReactMarkdown from 'react-markdown'

import { resize } from '../../helpers/imageService'

const Markdown = props => (
  <SbEditable content={props.blok}>
    <ReactMarkdown
      source={props.blok.Content}
      className={`markdown ${props.className ? props.className : ''}`}
      linkTarget={url => {
        if (url.match('http')) {
          return '_blank'
        }
        return undefined
      }}
      renderers={{
        image: image => {
          if (image.src.startsWith('//a.storyblok.com')) {
            return <img src={resize(image.src, '800x0')} alt={image.alt} />
          }
          return 'img'
        },
      }}
    />
  </SbEditable>
)

export default Markdown
