import React from 'react'
import SbEditable from 'storyblok-react'
import { Carousel as WebUICarousel } from '@ef-global/web-ui-react/lib/components/Carousel'
import CarouselSlide from './carousel-slide'

const swiperConfig = loop => {
  return {
    loop,
  }
}

const Carousel = props => {
  const {
    blok: { small_cards, loop, items },
  } = props
  return (
    <SbEditable content={props.blok}>
      <WebUICarousel smallCards={small_cards} swiperConfig={swiperConfig(loop)}>
        {items &&
          items.map(slide => {
            return (
              <div className="swiper-slide" key={slide._uid}>
                <CarouselSlide blok={slide} />
              </div>
            )
          })}
      </WebUICarousel>
    </SbEditable>
  )
}

export default Carousel
